import { makeStyles } from '@material-ui/core/styles';

const prStyles = makeStyles(theme => ({
    modalRoot: {
        position: 'absolute',
        width: '100%',
        maxWidth: '675px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2),
        
        [theme.breakpoints.down(400)]: {
            padding: theme.spacing(1),
        },
        '& .modal-body': {
            position: 'relative',
            '& .close-button': {
                position: 'absolute',
                top: 0,
                right: 0,
            },
            '& .MuiDivider-root': {
                marginBottom: theme.spacing(2),
            },
            '& .MuiGrid-item > .MuiTypography-root': {
                
                '&.MuiTypography-h5': {
                    paddingBottom: 0
                },
                '&.MuiTypography-subtitle2': {
                    fontStyle: 'italic',
                    color: '#36454F',
                    paddingBottom: theme.spacing(1),
                    
                },
                
                
                
            },
            '& .justification-container': {
                maxWidth:  '100%',
                maxHeight: '10ch',
                overflow: 'auto',
                marginBottom: theme.spacing(2),
                '&.MuiTypography-subtitle1':{
                    wordBreak: 'break-word',
                    maxWidth: '100%',
                    
                },
            },
            
            '& .MuiGrid-item > fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.23)',
                padding: '0 8px',
                overflow: 'hidden',                
                borderStyle: 'solid',
                borderWidth: '1px',
                borderRadius: '4px',
                margin: theme.spacing(0,0,2),
                '& > legend': {
                    padding: theme.spacing(0, 1),
                    fontSize: 18
                }

            },
            '& .reject-color': {
                color: 'rgb(163,40,0)',

            },
            '& .accept-color': {
                    
                color: 'rgb(35,107,0)',
            },
            '& .results-container': {
                margin: theme.spacing(1, 0),
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridGap: theme.spacing(1),
                '& .MuiDivider-root': {
                    marginBottom: theme.spacing(0),
                },
                '& .MuiList-root': {
                    padding: 0
                },
                
                '& .accept': {
                    color: '#36454F',
                    '& .MuiTypography-root.MuiTypography-subtitle2': {
                        backgroundColor: 'rgba(75,181,67, 0.3)',
                        color: '#007F00',
                    },
                    
                    '& .MuiIconButton-root': {
                        color: 'inherit'
                    },
                    
                },
                '& .denial': {                    
                    color: '#36454F',
                    '& .MuiTypography-root.MuiTypography-subtitle2': {
                        backgroundColor: 'rgba(204,51,0, 0.3)',
                        color: 'rgb(163,40,0)',
                    },
                    '& .MuiIconButton-root': {
                        color: 'inherit'
                    },                    
                },
                '& .MuiTypography-root': {
                    padding: theme.spacing(1,0),
                    fontWeight: 600,
                    
                },
                
            },
            '& .MuiGrid-item': {
                '& .MuiTextField-root': {
                    width: '100%',
                    marginBottom: theme.spacing(2),
                }
            },
            '& .MuiButton-root': {
                backgroundColor: '#140a9a',
                color: 'white',
                '& > .MuiButton-label': {
                    justifyContent: 'center'
                },
                '&:disabled': {
                    color: 'grey',
                    boxShadow: 'none',
                    backgroundColor: 'lightgrey',
                }


                
            }
        }
    }
}));

export default prStyles;