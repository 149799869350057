import { makeStyles } from '@material-ui/core';

const requestStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        paddingTop: theme.spacing(8),       
        margin: '0 auto',

        '& .MuiTextField-root': {
            margin: theme.spacing(1),

            '& .MuiOutlinedInput-root.Mui-focused': {                
                '& fieldset': {
                    borderColor: '#140a9a',
                },                
            },
            '& label.Mui-focused': {
                color: '#140a9a',
            }
        },
        '& .MuiButtonBase-root': {
            backgroundColor: '#140a9a',
            margin: theme.spacing(1),

            '&:hover': {
                backgroundColor: theme.palette.info.dark,
            },
            '&:disabled': {
                backgroundColor: 'lightgrey',
                color: 'darkgrey',
            }
        },
        '& .MuiGrid-item:first-of-type': {            
            width: '100%',
            maxWidth: '65ch',            

            '& > p': {
                margin: theme.spacing(1),                
                fontSize: '18px',
                fontWeight: 'bolder',
                maxWidth: '100%',
                padding: theme.spacing(1),
            },
        }, 
        
    },
    limitWidth: {
        width: '100%',
        maxWidth: '65ch',
        
        '& > .MuiGrid-container': {
            padding: theme.spacing(1),
            paddingTop: theme.spacing(0),

            '& .MuiGrid-item:first-of-type': {
                padding: theme.spacing(1),

                '& .MuiTextField-root': {
                    width: '100%',
                    margin: theme.spacing(0),
                }
            },
            '& .MuiButtonBase-root': {
                alignSelf: 'flex-end',
            },
            
        },
        
    },
    paddingLarge: {
        padding: theme.spacing(3),
    },
    smallPaddingTop: {
        paddingTop: theme.spacing(2),
    },
    header: {
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(0),
    },
    divider: {
        marginBottom: theme.spacing(2),
    },
    imgFluid: {
        maxWidth: '100%',
        height: 'auto',
    },
}));

export default requestStyles;