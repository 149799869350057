import React, { useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import LoadingSpinner from '../LoadingSpinner/';
import NavBar from "../NavBar/NavBar.js";
import FeatureRequest from '../FeatureRequest/';
import fetchFromApi from "../../apiFetch";
import routes from '../../routes';
import '../../App.css';

const AppWrapper = ({ children, appHeaderText,  navColor }) => {
    const mounted = useRef();
    const [ loaded, setLoaded ] = useState(false);
    const [ hasError, setError ] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector(store => store.auth.user);
    const navItems = useSelector(store => store.nav.navItems);
    const badgeState = useSelector(store => store.nav.badgeState);
    //const authInfo = accountInfo;
    
    const theme = createMuiTheme({
        navColor: navColor,
    });
    
    useEffect(()=> {
        mounted.current = true;
        
        const getUser = async () => {
            
           
            await fetchFromApi({ controller: "users", method: "getUserAndPermissions", refreshToken: true })
              .then(data => {         
                    
                    if(mounted.current){
                        const includeRequestPermissions = !Object.keys(data.user.permissions).every(key => data.user.permissions[key]);
                        const authorizedRoutes = routes.filter(route => {
                            if(route.requiresPerms) return includeRequestPermissions

                            return (
                                data.user.permissions.isActive &&
                                (
                                    (route.requiresAuth && (data.user.permissions.canPullNpd || data.user.permissions.canPullProduction))
                                    || 
                                    (route.requiresAdmin && data.user.permissions.isAdmin)                                    
                                )
                            );
                        });
                        const actions = [
                            {
                                type: 'CURRENT_USER',
                                payload: data.user,
                            },
                            {
                                type: 'SET_NAV_STATE',
                                payload: authorizedRoutes,
                            },
                            {
                                type: 'SET_BADGE_STATE',
                                payload: 
                                    data?.pendingRequests?.active &&
                                    data.user?.permissions?.isAdmin
                                        ? {                                            
                                            counts: data?.pendingRequests?.counts,
                                        } 
                                        : {                                            
                                            counts: {}
                                        },
                            }
                        ];
                                             
                        actions.map(action => dispatch(action));
                        setLoaded(true);
                    }
                })
                .catch(error => {
                    console.log('error in appwrapper', error);                        
                    setError(true);
                });
        }       
        
        if (!loaded && !user.userGUID && mounted.current) {  
            
            getUser();
        }

        return () => mounted.current = false;

    }, [ loaded, dispatch, user ]);    
    
    return ( 
        
            <div className="App">
                <ThemeProvider 
                    theme={theme}
                >
                    <NavBar
                        badgeState={ badgeState }
                        shelfNavItems={ navItems }
                        appHeaderText={ appHeaderText } 
                    />
                </ThemeProvider>
                { 
                    !loaded && !hasError && mounted.current
                        ? <LoadingSpinner />
                        : !hasError
                            ? user.userGUID 
                                ? children 
                                : <LoadingSpinner /> 
                            :   <div                                   
                                    style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: '14px', fontSize: '20px'}}        
                                >
                                    Try refreshing the page.
                                </div> 
                }
                {/* {
                    user && user.permissions && user.permissions.isActive && 
                    <FeatureRequest />    
                }    */}         
            </div>
        
    )
}

export default AppWrapper;