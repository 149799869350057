import React, { useState, useEffect, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Collapse, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Badge, Button, makeStyles, SvgIcon } from '@material-ui/core';
import './nav.css';

const useStyles = makeStyles((theme) => ({
        root: {
        width: '100%',
        //maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        },
        nested: {
            paddingTop: theme.spacing(0),
            paddingLeft: theme.spacing(3),
            '& .MuiListItem-root.side-nav-item': {
                
                color: '#140a9a',
                margin: '0 auto',
                textAlign: 'left',
                width: '100%',
                textDecoration: 'none',
                textTransform: 'uppercase',
            },
            '& a.MuiListItem-root.active': {
                position: 'relative',
                cursor: 'default',
                '& .MuiListItemText-root > .MuiTypography-root': {
                    fontWeight: 'bolder'
                }
            },        
            '& .MuiListItem-root.side-nav-item:hover': {
                textDecoration: 'none',
            },
            '& .MuiListItem-root.side-nav-item:active': {
                textDecoration: 'none',
            },
            '& .MuiListItemIcon-root': {
                minWidth: '42px',
            },
            '& .MuiListItemText-root > .MuiTypography-root': {
                fontSize: '0.875rem',
                lineHeight: '1.75',
                fontWeight: 500,
                letterSpacing: '0.02857em',
            }
        },
        

    }
));

const CollapsibleMenuItem = ({ items, label, iconPath, handleItemClick, badgeState }) => {
    
    const classes = useStyles();
    const [ isOpen, setIsOpen ] = useState(undefined);
    const location = useLocation();
    
    const handleToggle = () => {
        setIsOpen(!isOpen);
    }
    useEffect(() => {
        /* if(isOpen === undefined && badgeState.active)
        { */
            
            if(badgeState.active)
                setIsOpen(badgeState.active);
            
        //}
    }, [ /* isOpen,  */badgeState ]);
    
    return(
        <Fragment>
            <Button
                className={`side-nav-item override`}
                disableRipple
                startIcon={
                    iconPath &&
                    <SvgIcon style={{color: "#140a9a"}}>
                        {iconPath}
                    </SvgIcon>
                }
                endIcon={
                    isOpen 
                        ?
                            <SvgIcon>
                                <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z"></path>
                            </SvgIcon>
                        :
                            <SvgIcon>
                                <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                            </SvgIcon>
                }
                onClick={handleToggle}
            >  
                {label}
            </Button>
            <Collapse in={isOpen}>
                
                <List 
                    component="div"
                    className={classes.nested}
                >
                {
                    items && items.map(({ label, to, iconPath, displayBadge=false, badgeName=null }, i) => {
                        const active = location.pathname === to;
                        const activeClass = active ? " active" : "";
                        const clickFunc = active ? e => e.preventDefault() : handleItemClick;

                        return(
                            <ListItem 
                                key={i} 
                                component={Link} 
                                to={to} 
                                onClick={clickFunc} 
                                className={`side-nav-item${activeClass}`}
                            >
                                <ListItemIcon>
                                    <SvgIcon style={{color: "#140a9a"}}>
                                    {
                                        iconPath
                                    }    
                                    </SvgIcon>
                                </ListItemIcon>
                                <ListItemText primary={label} />
                                {
                                    displayBadge 
                                    && badgeState
                                    && badgeState.active
                                    && badgeState.counts[badgeName] > 0 
                                    && 
                                    <ListItemSecondaryAction>
                                        <Badge color="error" badgeContent={badgeState.counts[badgeName]} />
                                    </ListItemSecondaryAction>
                                }
                            </ListItem>
                        )
                    })
                }
                </List>
               
            </Collapse>
        </Fragment>
    );
}

export default CollapsibleMenuItem;