import {
    TextField, Button, withStyles } from '@material-ui/core';

export const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#140a9a',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': { 
            
            '&:hover fieldset': {
                borderColor: '#140a9a',
                borderWidth: '2px',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#140a9a',
            },
        },
    },
        
})(TextField);
    
export const StyledButton = withStyles({
    root: {
        backgroundColor: '#140a9a',
        paddingTop: '15px',
        paddingBottom: '15px',
        '& .MuiButton-label': {
            justifyContent: 'center'
        }
    },
    label: {
        textTransform: 'capitalize',
        fontWeight: 'bolder',
    }
})(Button);