import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 675,
        margin: theme.spacing(0, 'auto'),        
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiPaper-root': {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: 600,
            padding: theme.spacing(2),
            [theme.breakpoints.down(600)]: {
                padding: theme.spacing(1),
            },
            margin: theme.spacing(1),
            '& .MuiTypography-root': {
                padding: theme.spacing(0, 1, 2),
                
                [theme.breakpoints.down(600)]: {
                    padding: theme.spacing(1,1,2),
                },
            },
            '& .MuiDivider-root': {
                marginBottom: theme.spacing(3),
                [theme.breakpoints.down(600)]: {
                    marginBottom: theme.spacing(2),
                },
            },
            '& .MuiButton-root': {
                backgroundColor: '#140a9a',
                color: 'white',
                '& > .MuiButton-label': {
                    justifyContent: 'center'
                },
                '&.Mui-disabled': {
                    color: 'rgba(0, 0, 0, 0.26)',
                    boxShadow: 'none',
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                }
            }
        }
    },
    formControl: {
        margin: theme.spacing(1, 1, 2),
        '&:last-child': {
            marginBottom: theme.spacing(1)
        },
        [theme.breakpoints.down(600)]: {
            margin: theme.spacing(1),
        },
        minWidth: 240, 
        maxWidth: '100%',
        '& .Mui-focused.Mui-focused': {
            '&.MuiFormLabel-root': {
                color: '#140a9a',
            },
            '& fieldset': {
                borderColor: '#140a9a',
            }
        } 
    },
    chips: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, auto)',
    },    
    chip: {
        backgroundColor: '#140a9a',
        color: 'white',
        margin: 1,
    }
}));

export default useStyles;