import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Grid, Paper, Typography, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import requestStyles from './requestStyles';
import baseUrl from '../../baseUrl';
import endpoints from '../../endpoints';


const RequestAccess = () => {
    const classes = requestStyles(); 
    const history = useHistory();
    const auth = useSelector(store => store.auth);

    const [ reasonText, updateReasonText ] = useState("");
    const [ submitting, setSubmitting ] = useState(false);    
    const [ hasRequest, setHasRequest ] = useState(null);
    const [ errorOccurred, setErrorOccurred ] = useState(false);    

    useEffect(() => {
        let mounted = true;        

        const getUserRequest = async () => {            
            const { jwtIdToken } = auth.aadResponse;
            const { base, checkRequest } = endpoints.users;
            const options = {                
                headers: {
                    authorization: `Bearer ${jwtIdToken}`,
                    
                },
            };
            
            await fetch(`${baseUrl}${base}${checkRequest}`, options)
                .then(response => response.json())
                .then(data => {
                    //console.log(data);
                    if(mounted){
                        //console.log("initial fetch", data)
                        setHasRequest(data.results[0]);
                        
                    }
                })
                .catch(error => {
                    console.log(error);
                    setErrorOccurred(true);
                });
        }
        if (auth.user.userGUID && !auth.user.isAuthorized && hasRequest === null && mounted) {  
                    
            getUserRequest();            
        } 
        
        
        return () => mounted = false;
        
    },[auth, hasRequest, history]);

    const handleSubmit = async () => {
        
        const { jwtIdToken } = auth.aadResponse;
        const { users: { base, accessRequest } } = endpoints;
        const options = {
            method: 'POST',
            headers: {
                authorization: `Bearer ${jwtIdToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ RequestJustification: reasonText }),
        };
        setSubmitting(true);  
        await fetch(`${baseUrl}${base}${accessRequest}`, options)
            .then(res => res.json())
            .then(data => {
                setSubmitting(false);
                setHasRequest(data.hasRequest[0]);
            })
            .catch(error => {
                console.log(error.message);
                setErrorOccurred(true);
            });
    }

    const handleChange = e => {
        updateReasonText(e.target.value);
    }
    
    return(         
        <Box className={`container full-height`}>
            
            <form 
                className={`${classes.root} ${classes.limitWidth}`} 
                noValidate 
                autoComplete="off"
            >
                <Typography className={classes.header} variant="h5">
                    Request Access
                </Typography>
                <Paper elevation={5} className={classes.smallPaddingTop} square>
                {
                    
                    !auth.user.isAuthorized 
                    && !hasRequest 
                    && hasRequest !== null 
                    && !errorOccurred
                    ?   <Grid 
                            container 
                            alignContent='center' 
                            alignItems='center'                         
                            direction='column'                    
                        >
                           
                            <Grid item className={classes.limitWidth}>
                                <Grid 
                                    container
                                    alignItems='flex-end'
                                    direction='column'                                
                                >
                                    <Grid item>
                                        <TextField
                                            multiline
                                            onChange={handleChange}
                                            label="Reason for Requesting Access"
                                            variant="outlined"
                                            rows=     {5}                                    
                                        />
                                    </Grid>
                                    <Grid item>
                                        { !submitting 
                                            ?
                                            <Button
                                                onClick={handleSubmit}
                                                variant="contained"
                                                color="primary"
                                                disabled={!(reasonText.length > 0)}
                                            >
                                                Request Access
                                            </Button>
                                            :
                                            <CircularProgress />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>                        
                        </Grid>
                    :   <Grid container 
                            alignContent='center' 
                            alignItems='center'                         
                            direction='column'
                            className={classes.paddingLarge}                    
                        >
                            <Grid item>
                                <Typography
                                    align="center"
                                >
                                {  
                                    !hasRequest
                                    && hasRequest != null
                                    && !errorOccurred
                                        ? "You don't have any current requests"
                                        : hasRequest && !errorOccurred
                                            ? "Your request has been submitted and a confirmation email has been sent. An administrator will review your request shortly."
                                            : "Oops, something happened, try refreshing the page and resubmitting"
                                    
                                }
                                </Typography>
                                
                            </Grid>
                        </Grid>
                }
                </Paper>
            </form>
            
        </Box>        
    );
}

export default RequestAccess;