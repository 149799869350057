

const initialState = {
    user: {}
};
  
export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CURRENT_USER":
            
            return {...state, user: action.payload}            
        case 'AAD_LOGIN_FAILED':
            return {...state, aadResponse: null}
        case 'AAD_LOGIN_ERROR':
            return {...state, aadResponse: action.payload}
        case 'AAD_LOGIN_SUCCESS':          
            return {...state, aadResponse: action.payload}
        case 'AAD_ACQUIRED_ID_TOKEN_SUCCESS':
            if(state.aadResponse) {                
                return {
                    ...state, 
                    aadResponse: { 
                        ...state.aadResponse, 
                        jwtIdToken: action.payload.idToken.rawIdToken 
                    }
                };
            }
            return state;        
        case 'SET_TOKEN':
            return Object.assign({}, state, {
                jwtIdToken: Object.assign({}, state.aadResponse.jwtIdToken, action.payload)
            });
       
        default:
            return state;
    }
}
  
export default authReducer;