import { makeStyles } from "@material-ui/core";

const faqStyles = makeStyles(theme => ({
    root: {
        maxWidth: 1000,
        margin: '0 auto',
        width: '100%',
        '& .title': {
            padding: theme.spacing(3, 3, 5),            
            width: '100%',
            margin: '0 auto'
        },
        '& .inner-container': {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            maxWidth: '850px',
            width: '100%',
            margin: '0 auto',
            padding: theme.spacing(1, 1, 0),
        },
        '& .body': {
            height: '100%',
            overflow: 'auto',
            padding: theme.spacing(0, 1, 1),
        },
        '& .custom-header': {
            display: 'block',
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
            
            '&::before, &::after': {
                position: 'relative',
                display: 'inline-block',
                verticalAlign: 'middle',
                content: '""',
                width: '33%',            
                height: 2,
                backgroundColor: '#140a9a'
            },
            '&::before': {
                right: '0.5em',
                marginLeft: '-50%'
            },
            '&::after': {
                left: '0.5em',
                marginRight: '-50%'
            }
        },
        '& .MuiPaper-root': {
            padding: theme.spacing(2),
            '&:first-child': {
                marginBottom: theme.spacing(3),
            },
            '& .MuiDivider-root': {
                margin: theme.spacing(2, 0, 3),                
            },
            '& .MuiAccordion-root': {
                
                boxShadow: theme.shadows[2],
                '&::before': {
                    content: 'unset'
                },
                '&.MuiPaper-root': {
                    padding: 0,
                    
                },
                '& .MuiAccordionSummary-content': {
                    textShadow: '0 0 1px currentColor',
                },
                '& .MuiList-root': {
                    maxWidth: '100%',
                    '& .MuiAvatar-root': {
                        backgroundColor: '#140a9a',
                    },
                    '& .res-img': {
                        paddingTop: theme.spacing(1),
                        maxWidth: '100%',
                    }
                }
                
            }
        }
    }
}));

export default faqStyles;