import React, { useEffect, useRef } from "react";
import { Button, SvgIcon, Badge } from "@material-ui/core";

import { useLocation, Link } from "react-router-dom";
import klogo from "../../images/klogo4.png";
import navStyles from './navStyles';
import './nav.css';
import CollapsibleMenuItem from "./collapsibleMenu";

const NavBar = ({ shelfNavItems, appHeaderText, badgeState={} }) => {
  const nav = useRef(); 
  const toggle = useRef();
  const shelf = useRef();
  const overlay = useRef();
  const location = useLocation();
  const classes = navStyles();
  
  

  const setClientNavOffsetHeight = () => {
    let nh = nav.current.clientHeight;
    
    document.documentElement.style.setProperty('--nh', `${nh}px`);
  }

  const handleToggle = ({ target: { checked } }) => {
    const x = window.scrollX;
    const y = window.scrollY;
    checked 
      ? window.onscroll = () => window.scrollTo(x,y)
      : window.onscroll = () => {};
    overlay.current.classList.toggle('active');  
    shelf.current.classList.toggle('open');
  }

  const handleNavClick = () => {
    const { current } = toggle;
    current.checked = !current.checked;
    handleToggle({ target: { checked: current.checked }});
  }
  
  useEffect(() => {
    
    window.addEventListener('resize', setClientNavOffsetHeight);

    return () => window.removeEventListener('resize', setClientNavOffsetHeight);
  }, []);

  
  return (
    <nav ref={ nav } id="nav" onLoad={setClientNavOffsetHeight} className={`navbar ${classes.navRoot} ${classes.navColor}`}>      
      <div className="toggle-container">
      {
        shelfNavItems && shelfNavItems.length > 0 &&
          
            <div className="side-nav-toggle">
              <Badge color="error" invisible={!badgeState.active}>
                <input type="checkbox" onChange={ handleToggle } ref={ toggle } id="check" />
                <label htmlFor="check">
                  <div className="btn1" id="btn1">
                    <span />
                    <span />
                    <span />
                  </div>
                </label>
              </Badge>
            </div>
          
      }
      </div>
      <div className="container">
        
        <div className="navbar-header">
          <Link to="/">
            <img src={ klogo } className="img-fluid brand-logo" alt="logo" />
          </Link>
          <h1 className="nav navbar-nav navbar-right">
            { appHeaderText }
          </h1>
        </div>
        
      </div>
      {
        shelfNavItems && shelfNavItems.length > 0 &&
        <div id="side-nav">
          <div className="side-nav-overlay" ref={ overlay } />
          <div className="side-nav-shelf" ref={ shelf }>
          {
            shelfNavItems.map(({ label, to=null, iconPath=null, children=null }, i) => {
              
              const active = location.pathname === to;
              const activeClass = active ? " active" : "";
              const clickFunc = active ? e => e.preventDefault() : handleNavClick;
              return(
                !children 
                ?
                  <Button
                    component={Link}
                    to={to}
                    key={i} 
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    className={`side-nav-item${activeClass}`} 
                    startIcon={
                      iconPath &&
                        <SvgIcon style={{color: "#140a9a"}}>
                          {iconPath}
                        </SvgIcon>
                    }                    
                    onClick={clickFunc}>  
                    
                      {label}
                      
                  </Button>
                : 
                  <CollapsibleMenuItem 
                    key={i} 
                    label={label}  
                    iconPath={iconPath} 
                    items={children}
                    badgeState={badgeState} 
                    handleItemClick={handleNavClick}
                  />
              )
            })
          }
          </div>
        </div>
      }  
    </nav> 
  );
};

export default NavBar;
