import React, { useCallback, useEffect, useState, Fragment } from 'react';
import fetchApi from '../../../apiFetch';
import useAdminStyles from '../adminStyles';
import usePrStyles from './permissionRequestStyles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import { PencilIcon, CloseIcon, AddPermissionIcon, DenyPermissionIcon } from '../../../icons';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';




const PendingPermissionRequests = () => {
    const dispatch = useDispatch();
    const classes = useAdminStyles();
    const prClasses = usePrStyles();

    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState(undefined);
    const [ modalOpen, setModalOpen ] = useState(false);
    const [ modalBody, setModalBody ] = useState(undefined);

    const getPendingRequests = useCallback(async () => {
        setLoading(true);

        fetchApi({
            controller: 'admin',
            method: "getAdditionalRequests",
            refreshToken: true
        })
        .then(data => {
            setData(data);
            setLoading(false);
            dispatch({
                type: 'SET_BADGE_STATE',
                payload: {
                    counts: { permissions: data.pendingRequests.length }
                }
            })            
        })

    }, [dispatch]);

    const handleModalOpen = (request) => () => {
        const modalCloseFunc = () => {
            handleModalClose();
            getPendingRequests()
        }
        setModalOpen(true);
        setModalBody(<ModalBody request={request} closeFunc={modalCloseFunc} />)
    }

    const handleModalClose = () => {
        setModalOpen(false);
        setModalBody(undefined);
    }

    useEffect(() => {
        if (!loading && !data) {
            getPendingRequests();
        }

    }, [getPendingRequests, loading, data]);

    
    return(
        <Grid
            container
            direction='row'
            justify='space-around'
            alignContent='center'
        >
            <Grid className={classes.controlListItemWidth} item>
                <Paper variant="outlined" square>
                    <Typography variant="h6" className={classes.title}>
                        Pending Permission Requests
                    </Typography>
                    <Divider />
                    <List dense>
                    {
                        loading
                            ?   <ListItem>
                                    <ListItemText
                                        align="center"
                                        primary={<CircularProgress color="primary" />}
                                    />
                                </ListItem>
                            :   data
                                    ?   data.pendingRequests.length > 0
                                            ?   data.pendingRequests
                                                .map(({ displayName, email, ...rest }, index ) => (
                                                    <ListItem 
                                                        button
                                                        onClick={handleModalOpen({displayName, email, ...rest})}
                                                        key={index}
                                                        divider={index !== data.pendingRequests.length-1}
                                                    >
                                                        <ListItemText
                                                            primary={displayName}
                                                            secondary={email}
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <IconButton onClick={handleModalOpen({displayName, email, ...rest})}>
                                                                <PencilIcon />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                ))
                                            :   <ListItem>
                                                    <ListItemText
                                                        align="center" 
                                                        className={classes.subtitle} 
                                                        primary="No Pending Requests"
                                                    />
                                                    
                                                </ListItem>
                                    :   <ListItem>
                                            <ListItemText
                                                align="center" 
                                                className={classes.subtitle} 
                                                primary="Try refreshing the page"
                                            />
                                        </ListItem>    
                    }
                    </List>
                </Paper>
            </Grid>
            <Modal 
                open={modalOpen} 
                onClose={handleModalClose}
                disableBackdropClick
            >  
                <div className={ prClasses.modalRoot }>
                    { modalBody }
                </div>
            </Modal>
        </Grid>
    )
}

export default PendingPermissionRequests;

const ModalBody = ({ request: { justification, userId, permissionId, displayName, email, permissions }, closeFunc=()=>{} }) => {
    const [ determineList, updateDetermineList ] = useState(
        Object.keys(permissions).filter(perm => permissions[perm])
    )
    const [ acceptList, updateAcceptList ] = useState([]);
    const [ rejectList, updateRejectList ] = useState([]);
    const [ comments, updateComments ] = useState('');
    const [ submitting, setSubmitting ] = useState(false);
    const [ submitted, setSubmitted ] = useState(false);
    const [ message, setMessage ] = useState("");
    const [ error, setError ] = useState(false);
    
    const acceptPermission = permission => () =>{
        determineList.length > 0 && determineList.indexOf(permission) !== -1 && 
            updateDetermineList([...determineList.filter(el => el !== permission)]);
        rejectList.length > 0 && rejectList.indexOf(permission) !== -1 && 
            updateRejectList([...rejectList.filter(el => el !== permission)])
        updateAcceptList([...acceptList, permission]);
    }

    const denyPermission = permission => () =>{
        determineList.length > 0 && determineList.indexOf(permission) !== -1 && 
            updateDetermineList([...determineList.filter(el => el !== permission)]);
        acceptList.length > 0 && acceptList.indexOf(permission) !== -1 && 
            updateAcceptList([...acceptList.filter(el => el !== permission)])
        updateRejectList([...rejectList, permission]);
    }

    const handleChange = e => comments.length <= 750 && updateComments(e.target.value);

    const submitRequest = () => {
        const requestToSubmit = {
            userId: userId,
            commentsToUser: comments.length > 0 ? comments: null,
            additionalPermissions: {
                ...Object.fromEntries(acceptList.map(key => [ key, true ])),
                ...Object.fromEntries(rejectList.map(key => [ key, false ]))
            }
        }
        setSubmitting(true);
        fetchApi({
            method: "determinePermissionRequest",
            controller: "admin",
            params: [permissionId],
            fetchOptions: {
                method: 'POST',            
                body: JSON.stringify(requestToSubmit),
            },
            refreshToken: true
        })
        .then(data => {
            setSubmitting(false);
            setSubmitted(true);
            setMessage(data.message);
        })
        .catch(() => {
            setSubmitting(false);
            setError(true);
            setMessage("Something happened when submitting. Try again when the modal refreshes.")
            setTimeout(() => {
                setSubmitted(false);
                setError(false);
            }, 3000)
        })
    }
    
    return(
        <Fragment>        
            <Grid container direction="column" className="modal-body">
                <IconButton
                    className="close-button"
                    size="small"
                    color="inherit"
                    onClick={ closeFunc }
                >
                    <CloseIcon />
                </IconButton>
                <Grid item>                    
                    <Typography variant="h5">{ displayName }</Typography>
                    <Typography variant="subtitle2">{ email }</Typography>                    
                </Grid>
                {
                    !submitting && !submitted && !error
                    ?
                    <Grid item container className="request-body" direction="column">
                        <div className="justification-container">
                            <Typography variant="subtitle1">User Justification:</Typography>
                            <Typography>{justification}</Typography>
                        </div>
                        <Divider />
                        <Grid item>
                            <fieldset>
                                <legend>Requested Permissions</legend>
                                {
                                    determineList.length > 0 &&
                                    <List dense>
                                    {
                                        determineList
                                            .map(permission => (
                                                
                                                <ListItem>                                            
                                                    <ListItemText
                                                        primary={permission.split(/(?=[A-Z])/)
                                                            .map(e => e[0].toUpperCase()+e.substring(1)).join(' ')}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <Tooltip title="Grant Permission">
                                                            <IconButton className="accept-color" onClick={acceptPermission(permission)}>
                                                                <AddPermissionIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Deny Permission">
                                                            <IconButton className="reject-color" onClick={denyPermission(permission)}>
                                                                <DenyPermissionIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            ))
                                    }
                                    </List>
                                }
                                {
                                    (acceptList.length > 0  || rejectList.length > 0)
                                    &&
                                    <div className="results-container">
                                    <Paper square className="accept" variant="outlined">
                                        <Typography align="center" variant="subtitle2">Granted</Typography>
                                        <Divider />
                                        <List dense>
                                        {
                                            acceptList.map( permission => (
                                                    <ListItem>
                                                        <ListItemText
                                                            primary={
                                                                permission.split(/(?=[A-Z])/)
                                                                    .map(e => 
                                                                        e[0].toUpperCase()+e.substring(1)
                                                                    ).join(' ')
                                                            }
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <Tooltip title="Deny Permission">
                                                                <IconButton 
                                                                    size="small" 
                                                                    onClick={denyPermission(permission)}
                                                                >
                                                                    <DenyPermissionIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )
                                            )
                                        }
                                        </List>
                                    </Paper>
                                    <Paper square className="denial" variant="outlined">
                                        <Typography align="center" variant="subtitle2">Denied</Typography>
                                        <Divider />
                                        <List dense>
                                        {
                                            rejectList.map( permission => (
                                                    <ListItem>
                                                        <ListItemText 
                                                            primary={
                                                                permission.split(/(?=[A-Z])/)
                                                                    .map(e => 
                                                                        e[0].toUpperCase()+e.substring(1)
                                                                    ).join(' ')
                                                            }
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <Tooltip title="Grant Permission">
                                                                <IconButton 
                                                                    size="small" 
                                                                    onClick={acceptPermission(permission)}
                                                                >
                                                                    <AddPermissionIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                )
                                            )
                                        }
                                        </List>
                                    </Paper>
                                </div>
                            }
                            </fieldset>
                        </Grid>
                        <Grid item>
                            <TextField
                                multiline
                                label="Comments To User"
                                variant="outlined"
                                value={comments}
                                onChange={handleChange}
                                rows={5}
                                rowsMax={5}
                            />
                        </Grid>
                        <Grid container item justify="flex-end">
                            <Button 
                                align="center"
                                variant="contained"
                                disabled={determineList.length > 0}
                                onClick={submitRequest}
                            >
                                Update Request
                            </Button>
                        </Grid>
                    </Grid>
                    :
                    !submitted && !error
                        ?   <Grid item container justify="center">
                                <CircularProgress />
                            </Grid>
                        :   <Grid item container justify="center">
                            {message}
                            </Grid>
                }
            </Grid>
        </Fragment>
    )
}