import React from 'react';
import '../Home/home.css';
import './spinner.css';


export const LoadingSpinner = (className) => {

    return(
        <div className="loading-container full-height" >
            <div className="lds-roller">
                <div></div><div></div>
                <div></div><div></div>
                <div></div><div></div>
                <div></div><div></div>
            </div>
        </div>
    )
}

export default LoadingSpinner;