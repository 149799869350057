import React, { useState, Fragment } from 'react';
import { Grid, IconButton, Button, SvgIcon, Divider, Typography, TextField, CircularProgress } from '@material-ui/core';
import CustomSwitch from '../../CustomSwitch/';
import useAdminStyles from '../adminStyles';
import fetchFromApi from '../../../apiFetch';

const ModalBody = ({ requestDetails, closeFunc }) => {
    const classes = useAdminStyles();
    const [ submittingRequest, setSubmittingRequest ] = useState(false);
    const [ responseMsg, setResponseMsg ] = useState("");
    const [ internalRequest, setInternalRequest ] = useState({
        ...requestDetails, 
        commentsToUser: ""
    });
    const [ permissions, setPermissions ] = useState(requestDetails.determinedPermissions);

    const updateRequest = async request => {
        
        const options = {
            method: 'POST',            
            body: JSON.stringify(request),
        }
        setSubmittingRequest(true);

        return await fetchFromApi({ 
                controller: "admin", 
                method: "determineRequest", 
                fetchOptions: options, 
                refreshToken: true 
            })            
            .then(data => {
                setResponseMsg(data.message);
                setSubmittingRequest(false);
            })
            .catch(error => {
                console.log(error);
                setSubmittingRequest(false)
            });
    }

    const handleSubmit = determination => async () => {
        
        const finalRequest = { 
            ...internalRequest,
            determinedPermissions: permissions,
            requestState: determination 
        };
        
        await updateRequest(finalRequest);
    }

    const handleUpdate = e => {

        setInternalRequest(prev => ({ ...prev, commentsToUser: e.target.value }));    
    }

    const handleUpdatePermission = key => () => {
        
        setPermissions(prev => ({ ...prev, [key]: !prev[key]  }));
    }
    
    return(
        
        <Fragment>
            <IconButton disabled={ submittingRequest } onClick={ closeFunc } 
            className={ classes.closeButton }>
                <SvgIcon>
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                </SvgIcon>
            </IconButton> 
            <Typography variant="h5" className={ classes.title }>
                Determine Access Request
            </Typography>
            <Typography className={ classes.subtitle }>
                { internalRequest.displayName }<br />
                { internalRequest.email }
            </Typography>
            <Divider />
            {
                !submittingRequest && responseMsg.length < 1 
                ?
                <Grid className={ classes.modalRoot } container>
                    <Grid item>
                        <TextField
                            label="User Access Justification"
                            multiline
                            variant="outlined"                            
                            InputProps={{
                                readOnly: true,
                            }}
                            rows={5}
                            defaultValue={ internalRequest.requestJustification }                            
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Notes to User (Optional)"
                            variant="outlined"
                            multiline
                            rows={5}                            
                            value={ internalRequest.commentsToUser }
                            onChange={ handleUpdate }                     
                        />
                    </Grid>
                    <Grid item container justify="flex-end">
                        <Grid item className='permissions'>
                            <fieldset>
                                <legend>Permissions (choose at least one if accepting)</legend>
                                <Grid container justify="flex-end">
                                    <Grid item>
                                        Production Number Gen
                                        <CustomSwitch onChange={handleUpdatePermission('production')} checked={permissions.production} />
                                    </Grid>
                                    <Grid item>
                                        NPD Number Gen
                                        <CustomSwitch onChange={handleUpdatePermission('npd')} checked={permissions.npd} />
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Grid>
                    </Grid>
                    <Grid item container justify="flex-end">
                        <Grid item>
                            <Button
                                variant="outlined"
                                className="success"
                                
                                onClick={ handleSubmit(true) }
                                startIcon={
                                    <SvgIcon>
                                        <path d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
                                    </SvgIcon>
                                }
                                disabled={!permissions.npd && !permissions.production}
                            >
                                Accept
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                className="reject"
                                onClick={ handleSubmit(false) }
                                startIcon={
                                    <SvgIcon>
                                        <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                                    </SvgIcon>
                                }
                            >
                                Reject
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                : 
                <Grid className={ classes.modalRoot } container>
                    <Grid className="Result-container" item>
                    {
                        !submittingRequest && responseMsg.length > 0
                        ?
                        <Typography align="center" variant="h6">
                            { responseMsg }
                        </Typography>
                        :
                        <CircularProgress />
                    }                        
                    </Grid>
                </Grid>
            }
        </Fragment>
    )
}

export default ModalBody;