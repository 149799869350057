
const routes = [
    { 
        to: '/',
        label: 'Part Number Generator', 
        iconPath: <path d="M12.09 2.91C10.08.9 7.07.49 4.65 1.67l4.34 4.34-3 3-4.34-4.34C.48 7.1.89 10.09 2.9 12.1c1.86 1.86 4.58 2.35 6.89 1.48l9.82 9.82 3.71-3.71-9.78-9.79c.92-2.34.44-5.1-1.45-6.99z"></path>,
        requiresAuth: true,       
    },
    { 
        to: '/myparts',        
        label: 'My Part Numbers', 
        iconPath: <path d="M2 17h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1 3h1.8L2 13.1v.9h3v-1H3.2L5 10.9V10H2v1zm5-6v2h14V5H7zm0 14h14v-2H7v2zm0-6h14v-2H7v2z"></path>,   
        requiresAuth: true,  
    },
    { 
        to: '/requestadditionalaccess',        
        label: 'Request Additional Access', 
        iconPath:  <path fill="currentColor" d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z" />,   
        requiresAuth: true,  
        requiresPerms: true,
    },
    {         
        label: 'Admin Tools',
        iconPath: <path d="M16.5 12c1.38 0 2.49-1.12 2.49-2.5S17.88 7 16.5 7 14 8.12 14 9.5s1.12 2.5 2.5 2.5zM9 11c1.66 0 2.99-1.34 2.99-3S10.66 5 9 5 6 6.34 6 8s1.34 3 3 3zm7.5 3c-1.83 0-5.5.92-5.5 2.75V19h11v-2.25c0-1.83-3.67-2.75-5.5-2.75zM9 13c-2.33 0-7 1.17-7 3.5V19h7v-2.25c0-.85.33-2.34 2.37-3.47C10.5 13.1 9.66 13 9 13z"></path>,
        requiresAdmin: true,
        children: [
            {
                label: 'Admin Portal',
                to: '/admin/home',
                iconPath: <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path>,                
            },
            {
                label: 'Pending Access Requests',
                to: '/admin/accessrequests',
                iconPath: <path d="M21 3H3v18h18V3zm-2 12h-4c0 1.66-1.35 3-3 3s-3-1.34-3-3H4.99V5H19v10zm-3-5h-2V7h-4v3H8l4 4 4-4z"></path>,
                displayBadge: true,
                badgeName: "access"
            },
            {
                label: 'Pending Permission Requests',
                to: '/admin/permissionrequests',
                iconPath: <path d="M2 12H4V17H20V12H22V17A2 2 0 0 1 20 19H4A2 2 0 0 1 2 17M11 5H13V8H16V10H13V13H11V10H8V8H11Z" />,
                displayBadge: true,
                badgeName: "permissions"
            },
            {
                label: 'User Control',
                to: '/admin/usercontrol',
                iconPath: <path d="M21 5v14h2V5h-2zm-4 14h2V5h-2v14zM15 5H1v14h14V5zM8 7.75c1.24 0 2.25 1.01 2.25 2.25S9.24 12.25 8 12.25 5.75 11.24 5.75 10 6.76 7.75 8 7.75zM12.5 17h-9v-.75c0-1.5 3-2.25 4.5-2.25s4.5.75 4.5 2.25V17z"></path>,
            },            
        ],
    },
    { 
        to: '/faq',        
        label: 'FAQ', 
        iconPath: <path d="M11.5 2C6.81 2 3 5.81 3 10.5S6.81 19 11.5 19h.5v3c4.86-2.34 8-7 8-11.5C20 5.81 16.19 2 11.5 2zm1 14.5h-2v-2h2v2zm0-3.5h-2c0-3.25 3-3 3-5 0-1.1-.9-2-2-2s-2 .9-2 2h-2c0-2.21 1.79-4 4-4s4 1.79 4 4c0 2.5-3 2.75-3 5z"></path>,
        requiresAuth: true,  
    },               
];

export default routes;