import React from 'react';
import useStyles from './customSwitchStyles';
import { Switch } from '@material-ui/core';


const CustomSwitch = ({...switchProps}) => {
    const classes = useStyles();

    return <Switch classes={{ root: classes.root }} {...switchProps} />;      
    
}

export default CustomSwitch;