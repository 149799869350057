import { MsalAuthProvider, LoginType } from 'react-aad-msal';

 
const config = {
  auth: {
    //authority: 'https://login.microsoftonline.com/4207c869-9c0b-497f-a0cd-b119c2da9188',
    //clientId: '459ce912-5257-4020-b3fb-e9ebe8057e2c',
    authority: 'https://login.microsoftonline.com/58be8688-6625-4e52-80d8-c17f3a9ae08a',
    clientId: '9c1c5f83-3290-4f95-9656-7277d9286c1b',
    redirectUri: window.location.origin,
    validateAuthority: true,

      // After being redirected to the "redirectUri" page, should user
      // be redirected back to the Url where their login originated from?
      navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true
  }
};
 
const authenticationParameters = {
  scopes: [
    'user.read'
  ]
}

const options = {
  loginType: LoginType.Redirect
}
 
export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)