import { useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { useDispatch } from 'react-redux';
import AppWrapper from './Components/AppWrapper/';
import ProtectedRoute, { AdditionalRequestWrapper, RequestAccessRouteWrapper } from './Components/ProtectedRoute';
import Home from './Components/Home/';
import ViewParts from './Components/ViewParts/';
import AdditionalRequest from './Components/AdditionalAccessRequest';
import AdminRouter from './Components/Admin/adminRouter';
import RequestAccess from './Components/RequestAccess';
import FaqPage from './Components/FAQ';
import { authProvider } from './authProvider';
import fetchFromApi from './apiFetch';
import store from './store';
import './App.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';



const App = () => {
  const headerObject = window.location.hostname.includes("beta") 
        ? { headerColor: 'green', headerModifier: ' DEV' }
        : window.location.hostname.includes("local")
            ? { headerColor: "#6fb42c", headerModifier: ' LOCAL' }
            : { headerColor: "#140a9a", headerModifier: '' };
  
  const { headerColor, headerModifier } = headerObject;
  const dispatch = useDispatch();

  const updateRequests = useCallback(async () => {
      if(!store.getState().auth.user?.permissions?.isAdmin)  return;
      
      await fetchFromApi({ controller: "admin", method: "getBadgeCounts" })
        .then(data => {
         
          dispatch({
            type: 'SET_BADGE_STATE',
            payload: {                    
                  counts: data.counts,
                } 
          });
        })
        .catch(error => console.log(error));
  }, [dispatch])

  useEffect(() => {
    let unmounted = false;
    let updater;
    if(!unmounted) {
        
        updater = setInterval(updateRequests, 45000);
    }
    
    return () => { 
      unmounted = true;
      clearInterval(updater)
    }
  }, [ updateRequests ]);
  
  return (
    
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <AzureAD provider={authProvider} forceLogin={true} reduxStore={store}>
      {
        ({ authenticationState, error }) => {
                    
          return(
            authenticationState === AuthenticationState.Authenticated

              ? ( 
                  <Router>
                    <AppWrapper 
                      appHeaderText={ "Part Number Generator" + headerModifier }                       
                      navColor={ headerColor }
                    >                
                      <Switch> 
                        <ProtectedRoute exact path="/">
                          <Home />
                        </ProtectedRoute>
                        <ProtectedRoute path="/myparts">
                          <ViewParts />
                        </ProtectedRoute>
                        <RequestAccessRouteWrapper path="/requestaccess">
                          <RequestAccess />
                        </RequestAccessRouteWrapper> 
                        <AdditionalRequestWrapper path="/requestadditionalaccess">
                          <AdditionalRequest />
                        </AdditionalRequestWrapper>                       
                        <ProtectedRoute requiresAdmin path="/admin/:id">
                          <AdminRouter />
                        </ProtectedRoute>                        
                        <ProtectedRoute path="/faq">
                          <FaqPage />
                        </ProtectedRoute>
                        <ProtectedRoute>
                          <div>404 page not found</div>
                        </ProtectedRoute>
                      </Switch> 
                    </AppWrapper> 
                  </Router>
                )
              : authenticationState === AuthenticationState.Unauthenticated
                &&                   
                ( 
                  error 
                    ? <div>There was an error during authentication, please try again</div> 
                    : <div>You will be redirected shortly to log in</div> 
                ) 
          )
        }
      }
      </AzureAD>
    </MuiPickersUtilsProvider>
  );
}

export default App;
