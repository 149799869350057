import { Box, Grid, Paper, TextField, MenuItem, Button, Typography } from '@material-ui/core';
import React, { useState, Fragment } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from '@date-io/moment';

const defaultFilterState = {
    search: "",
    partType: "both",
    dateRange: { start: undefined, end: new Date() }
}

const FilterToolbar = ({ partList, children }) => {
      
    const [ filterList, updateFilterList ] = useState(partList);    
    const [ filterVals, setFilterVals ] = useState(defaultFilterState);
    
    const updateFilters = (filterState) => {
        const momentUtil = new moment();
        let updatingList = partList;

        const filterFuncs = {
            search: searchValue => ({ partNum }) => partNum.toLowerCase().includes(searchValue),
            partType: updatedPartType => {
                switch(updatedPartType) {
                    case 'both':
                        return ({partNum}) => partNum.includes("R") || partNum.includes("NPD_");
                        
                    case 'production':
                        
                        return ({partNum}) => partNum.includes("R");
                    case 'npd':
                        
                        return ({partNum}) => partNum.includes("NPD_");
                    default:
                        throw new Error();
                }
            },
            dateRange: updatedDateRange => ({ pullTime }) => {
                if(updatedDateRange.start !== undefined)    
                    return  (
                                momentUtil.isAfter(momentUtil.date(pullTime), momentUtil.date(updatedDateRange.start))
                                || momentUtil.isSameDay(momentUtil.date(pullTime), momentUtil.date(updatedDateRange.start))
                            )
                            &&  
                            ( 
                                momentUtil.isBefore(momentUtil.date(pullTime), momentUtil.date(updatedDateRange.end))
                                || momentUtil.isSameDay(momentUtil.date(pullTime), momentUtil.date(updatedDateRange.end)) 
                            )
                else return true; 
            },
        };

        Object.keys(filterState).forEach(
            filterValKey => 
                updatingList = updatingList.filter(filterFuncs[filterValKey](filterState[filterValKey]))            
        );
        updateFilterList(updatingList);
    }
  
    const handlePartChange = e => {
        const updatedVals = { ...filterVals, partType: e.target.value }

        setFilterVals(updatedVals);
        updateFilters(updatedVals);
    }

    const handleDateChange = inputName => date => {        
        const updatedDateRange = { ...filterVals.dateRange, [inputName]: date }
        
        setFilterVals({ ...filterVals, dateRange: updatedDateRange });
        updateFilters({ ...filterVals, dateRange: updatedDateRange });
    }

    const handleSearch = e => {
        const nextState = { ...filterVals, search: e.target.value };

        setFilterVals(nextState);
        updateFilters(nextState);
    }
    
    const clearFilters = () => {
        setFilterVals({ ...defaultFilterState });
        updateFilterList(partList);
    }

    return (
        <Fragment>  
             
            <Paper
                className="filter-control-container"
                elevation={3} 
                square
            >
                <Grid container>
                    <Grid item container>
                        <Grid item>
                            <TextField
                                variant="outlined"
                                label="Search By #"
                                id="search"
                                type="number"
                                value={filterVals.search}
                                size="small"
                                onChange={handleSearch}
                            />                        
                        </Grid>
                        <Grid item>
                            <TextField                                
                                variant="outlined"
                                label="Part Type"
                                value={filterVals.partType}
                                select
                                size="small"
                                
                                onChange={handlePartChange}
                                SelectProps={
                                    {
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null
                                        }
                                    }
                                }
                            >
                                <MenuItem value="both">
                                    Both
                                </MenuItem>
                                <MenuItem value="npd">
                                    NPD    
                                </MenuItem> 
                                <MenuItem value="production">
                                    Production
                                </MenuItem>                            
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item>
                            <KeyboardDatePicker 
                                orientation="landscape"
                                label="Start Date"
                                variant="inline"
                                value={filterVals.dateRange.start}
                                maxDate={filterVals.dateRange.end}
                                onChange={ handleDateChange("start") }
                                inputVariant="outlined"
                                size="small"
                                
                                format="MM/DD/YYYY"
                            />
                        </Grid>
                        <Grid item>
                            <KeyboardDatePicker 
                                orientation="landscape"
                                label="End Date"
                                variant="inline"
                                value={filterVals.dateRange.end}
                                minDate={filterVals.dateRange.start}
                                maxDate={new Date()}
                                onChange={ handleDateChange("end") }
                                inputVariant="outlined" 
                                size="small"
                                format="MM/DD/YYYY"
                            />
                        </Grid>
                    </Grid>
                    <Grid item container justify="flex-end" alignItems="center">
                        
                        <Button
                            variant="contained"  
                            onClick={clearFilters} 
                                                     
                        >
                            reset filters
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            <Grid className="results" container>
                <Grid item >
                    <Typography component="span">Total Part Numbers: </Typography>
                    { partList && partList.length }
                </Grid>
                <Grid item>
                    <Typography component="span">Displayed Results: </Typography>
                    { partList && filterList.length}
                </Grid>
            </Grid>     
            <Box className="list-container">
            {
                children(filterList)
            }
            </Box>
        </Fragment>
    );
}

export default FilterToolbar;