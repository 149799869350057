import React from 'react';
import { Route, Redirect }  from 'react-router-dom';
import store from '../../store';

const ProtectedRoute = ({ requiresAdmin = false, children, ...rest }) => {
    const { auth: { user } } = store.getState();
    const rootAuth = user.permissions.canPullNpd || user.permissions.canPullProduction;
    const adminAuth = user.permissions.isAdmin;
    
    return (
        requiresAdmin 
        ?
            <Route 
                {...rest} 
                render={ ({ location }) => {
                    let pathname = rootAuth
                        ? "/"
                        : "/requestaccess"
                    
                    return adminAuth
                        ?   children
                        :   <Redirect 
                                to={{
                                    pathname: pathname,
                                    state: { from: location }
                                }}
                            />
                }}
            />
        :
            <Route 
                {...rest} 
                render={ ({ location }) => {
                    let pathname = adminAuth 
                        ? "/admin/home" 
                        : "/requestaccess";

                    return rootAuth
                        ?   children
                        :   <Redirect 
                                to={{
                                    pathname: pathname,
                                    state: { from: location }
                                }}
                            />
                }}
            />
    )
    
}

export default ProtectedRoute;

export const RequestAccessRouteWrapper = ({ children, ...rest }) => {
    const { auth: { user } } = store.getState();
    const rootAuth = user.permissions.canPullNpd || user.permissions.canPullProduction;
    const adminAuth = user.permissions.isAdmin;

    return(
        <Route 
            {...rest} 
            render={ ({ location }) => {
                let pathname = rootAuth && adminAuth 
                    ? "/" 
                    : adminAuth 
                        ? "/admin/home"
                        : "/"
                    
                return !rootAuth && !adminAuth
                    ?   children
                    :   <Redirect 
                            to={{
                                pathname: pathname,
                                state: { from: location }
                            }}
                        />
            }}
        />
    )
}

export const AdditionalRequestWrapper = ({ children, ...rest }) => {
    const { auth: { user } } = store.getState();
    const auth = user.permissions.canPullNpd 
        || user.permissions.canPullProduction 
        || user.permissions.isAdmin;
    

    return(
        <Route 
            {...rest} 
            render={ ({ location }) => {
                
                    
                return auth
                    ?   children
                    :   <Redirect 
                            to={{
                                pathname: "/requestaccess",
                                state: { from: location }
                            }}
                        />
            }}
        />
    )
}