import { Badge, Box, Divider, Paper, SvgIcon, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import routes from '../../routes';
import useAdminStyles from './adminStyles';

const AdminLayout = ({children}) => {
   
    const classes = useAdminStyles();
    
    return(
        <Box className={`${classes.root} ${classes.outerContainer} full-height`}>
            <Paper   
                className='container full-height'                 
                square
                elevation={0}
            >       
                {children}
            </Paper>
        </Box>
    )
}
export default AdminLayout;
    
export const AdminHome = () => {
    const classes = useAdminStyles();
    const [ , , , adminRoutes ] = routes;
    const { user } = useSelector(store => store.auth);
    const { badgeState } = useSelector(store => store.nav);
    
    
    return (
        <div className={classes.adminHomeRoot}>
            <Typography variant="h6">Welcome {user.displayName.split(" ")[0]}</Typography>
            <Paper  elevation={3} square>
                <Typography variant="h6">Admin Tools</Typography>
                <Divider />
                <div className="grid-container">
                {
                    adminRoutes.children.filter(({to}) => to !== window.location.pathname)
                        .map(({ to, label, iconPath = null, displayBadge = null, badgeName }, ind) => (
                                <Box className="grid-link" key={ind} component={Link} to={to}>                                    
                                   
                                    <div className="grid-link-inner">
                                        <div className="grid-link-body">                                            
                                            <Typography className="grid-link-header">
                                                {label}
                                            </Typography>
                                            { 
                                                iconPath && 
                                                <div className="grid-link-icon">
                                                <Badge 
                                                    color="error"
                                                    invisible={displayBadge && badgeState && badgeState.counts[badgeName] === 0} 
                                                    badgeContent={displayBadge 
                                                       
                                                        && badgeState.counts[badgeName]}
                                                >
                                                    <SvgIcon>
                                                        {iconPath}
                                                    </SvgIcon>
                                                </Badge>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Box>
                            )
                        )
                }
                </div>
            </Paper>
        </div>
    )
}