import React from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import AdminLayout, { AdminHome } from './';
import PendingRequests from './PendingRequests/pendingRequests';
import PermissionRequests from './PermissionRequests/index';
import UserPermissions from './UserPermissions/userPermissions';


const AdminRouter = () => {
    const { params: { id }, path } = useRouteMatch();    
    
    return (
        <AdminLayout>
            <RenderAdminComponent id={id} path={path} />
        </AdminLayout>
    )
}

const RenderAdminComponent = ({ id, path }) => {
    
    switch(id){
        case 'accessrequests':
            return <PendingRequests />;
        case 'usercontrol':
            return <UserPermissions />;
        case 'home':
            return <AdminHome />;
        case 'permissionrequests':
            return <PermissionRequests />
        default:
            return <Redirect 
                        to={{
                            pathname: '/404',
                            state: { from:  path }
                        }}
                    />;
        
    }
}
export default AdminRouter;