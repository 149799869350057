import React from 'react';
import { Button, Grid, SvgIcon } from '@material-ui/core';
import homeStyles from '../homeStyles';

const PNGToolbar = ({partNums, snackSetter, error, fetching}) => {
    const classes = homeStyles();

    const handleCopyAll = async () => {
        const textArea = document.createElement("textarea");
        textArea.value = partNums.join('\n');
        textArea.style.position = "absolute";
        textArea.style.left = "-99999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select(); 
        
        await new Promise((res,rej) => {
            document.execCommand("copy") ? res() : rej();
            textArea.remove();
        })
            .then(() => {
                const message = "All part numbers copied to clipboard"
                snackSetter(prev => [ ...prev, { message, key: new Date().getTime() } ]);
            })
            .catch(error => console.log(error));
    }

    const handleSave = () => {
        const url = URL.createObjectURL(new Blob([partNums.join('\n')], {type: 'text/plain'}));
        const anchor = document.createElement('a');
        const date = new Date();
        anchor.style.display = 'none';
        anchor.href = url;
        anchor.download = `PartNumbers_${date.getUTCMonth() + 1}-${date.getUTCDate()}-${date.getFullYear()}_${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.txt`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    
    return (
        !error && !fetching && partNums 
            ?
            <Grid            
                className={ `${ classes.fullWidth } ${ classes.noVertMargin }` } 
                container 
                justify='flex-end'
            >                         
                {
                    partNums && partNums.length > 1 &&
                    <Grid item>
                        <Button
                            className={ classes.copyAllBtn }
                            endIcon={
                                <SvgIcon>
                                    <path d="M7 15h7v2H7zm0-4h10v2H7zm0-4h10v2H7zm12-4h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-.14 0-.27.01-.4.04-.39.08-.74.28-1.01.55-.18.18-.33.4-.43.64-.1.23-.16.49-.16.77v14c0 .27.06.54.16.78s.25.45.43.64c.27.27.62.47 1.01.55.13.02.26.03.4.03h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                                </SvgIcon>
                            }
                            onClick={ handleCopyAll }
                        >
                            Copy All
                        </Button>
                    </Grid>
                }
                <Grid item>
                    <Button
                        className={ classes.copyAllBtn }
                        endIcon={
                            <SvgIcon>
                                <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"></path>
                            </SvgIcon>
                        }
                        onClick={ handleSave }
                    >
                        Download
                    </Button>
                </Grid>
            </Grid>
            :
            <div />
    );

}

export default PNGToolbar;