import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useStyles from './faqstyles';
import { partSelection, partCount, updatePermissions, partHistory,
    selectPermissions, generated, adminDropdown, viewLogs, myPartsLink, myParts } from './imgs/faq-imgs';
import { HamburgerIcon, ToolIcon, NumberIcon, CogIcon, FilterIcon,
    ExpandMoreIcon, PermissionsIcon, OpenDialogIcon, ClipboardIcon, MyPartsIcon } from '../../icons';
import { Divider, Typography, Paper, Accordion, AccordionSummary, AccordionDetails, 
    Grid, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@material-ui/core';


const FaqPage = () => {
    const classes = useStyles();
    const { permissions } = useSelector(store => store.auth.user);
    const [ expanded, setExpanded ] = useState({ k: false, a: false });    

    const handleChange = (key, panel) => (event, isExpanded) => {
        
        setExpanded({ 
            ...expanded, 
            [key]: isExpanded ? panel : false 
        });
        
    };
    
    
    return(

        <div className={`${classes.root} full-height`}>
            <div className="inner-container">
                <div className="title">
                    <Typography variant="h5" align="center" className="custom-header">
                        Frequently Asked Questions
                    </Typography>
                </div>
                <div className="body">
                {
                    (permissions.canPullNpd || permissions.canPullProduction) && 
                    <Paper square variant="outlined">
                        <Typography variant="h6">KPNG Questions</Typography>
                        <Divider />
                        <Accordion 
                             expanded={expanded.k === 1}
                             onChange={handleChange('k', 1)}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                How do I generate part numbers?
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography>To generate a part number:</Typography>
                                    </Grid>
                                    <List>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar variant="square">
                                                    <ToolIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText 
                                                primary={
                                                    <Typography>
                                                        Select your <strong>Part Type</strong> by choosing an available option in the <em>Part Type select menu</em>.
                                                    </Typography>
                                                }
                                                secondary={
                                                    <img className="res-img" src={partSelection} alt="part-selection" />
                                                }
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar variant="square">
                                                    <NumberIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText 
                                                primary={
                                                    <Typography>
                                                        Adjust the <strong>Part Count</strong> to a number greater than zero (this indicates the number of part numbers you would like to generate). Simply type any number between 1 and 25, or increment (or decrement) using the arrow buttons as shown below.
                                                    </Typography>
                                                }
                                                secondary={
                                                    <img className="res-img" src={partCount} alt="part count" />
                                                }
                                            />
                                        </ListItem>   
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar variant="square">
                                                    <CogIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText 
                                                primary={
                                                    <Typography>
                                                        The <strong>Generate Part Number(s)</strong> button should become enabled after selecting a part and supplying the count of part numbers you require. Simply press the button, and a list of part numbers should appear below.
                                                    </Typography>
                                                }
                                                secondary={
                                                    <img className="res-img" src={generated} alt="generate" />
                                                }
                                            />
                                        </ListItem>                
                                    </List>
                                </Grid>  
                                   
                            </AccordionDetails>
                        </Accordion>
                        <Accordion 
                            expanded={expanded.k === 2}
                            onChange={handleChange('k', 2)}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                Why do I only have 1 Part Type available?
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    Your ability to pull part numbers is determined by a set of permissions that are controlled by your administrator.  If you have only one option available in the Part Type selection box (Production or NPD) then you have only been assigned with that specific permission and it is up to your administrator to provide additional access.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion 
                            expanded={expanded.k === 3}
                            onChange={handleChange('k', 3)}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                Why can I only generate up to 25 part numbers at a time?
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    It is possible to bypass this restriction with a special permission that an adminstrator can grant you.  However, most scenarios should not require more than 25 part numbers at once. 
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion 
                             expanded={expanded.k === 4}
                             onChange={handleChange('k', 4)}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                How do I tell what part numbers I have pulled?
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography>To view your personal part number history:</Typography>
                                    </Grid>
                                    <List>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar variant="square">
                                                    <HamburgerIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText 
                                                primary={
                                                    <Typography>
                                                        Access the <strong>My Part Numbers</strong> link in the <HamburgerIcon /> hamburger navigation menu.
                                                    </Typography>
                                                }
                                                secondary={
                                                    <img className="res-img" src={myPartsLink} alt="part-selection" />
                                                }
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar variant="square">
                                                    <MyPartsIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText 
                                                primary={
                                                    <Typography>
                                                        Accessing the <strong>My Part Numbers</strong> link will pull up a list of every part number you've pulled with several filters <em>(by part #, by part type and by date range)</em>, all of which work independently of, or in conjunction, with each other.
                                                    </Typography>
                                                }
                                                secondary={
                                                    <img className="res-img" src={myParts} alt="part count" />
                                                }
                                            />
                                        </ListItem>   
                                                  
                                    </List>
                                </Grid>  
                                   
                            </AccordionDetails>
                        </Accordion>
                    </Paper>
                }
                {
                    permissions.isAdmin && 
                    <Paper square variant="outlined">
                        <Typography variant="h6">Admin Questions</Typography>
                        <Divider />
                        <Accordion 
                            expanded={expanded.a === 1}
                            onChange={handleChange('a', 1)}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>How do I update a user's permissions?</AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography>To update a user's permissions:</Typography>
                                    </Grid>
                                    <List>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar variant="square">
                                                    <HamburgerIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText 
                                                primary={
                                                    <Typography>
                                                        Access the <strong>Hamburger Navigation Menu</strong> in the upper-left corner of the screen. Within the <strong>Admin Tools</strong> collapsible sub-menu, access the <strong>User Control</strong> link.
                                                    </Typography>
                                                }
                                                secondary={
                                                    <img className="res-img" src={adminDropdown} alt="dropdown" />
                                                }
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar variant="square">
                                                    <PermissionsIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText 
                                                primary={
                                                    <Typography>
                                                        Find the user, within the list of users, whose permissions you want to update then hover over and press the <PermissionsIcon /> button next to that user's name.
                                                    </Typography>
                                                }
                                                secondary={
                                                    <img className="res-img" src={updatePermissions} alt="update permissions" />
                                                }
                                            />
                                        </ListItem>   
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar variant="square">
                                                    <OpenDialogIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText 
                                                primary={
                                                    <Typography>
                                                        In the modal that pops over the user list, there are a series of labeled switches that indicate the current state of the user's permissions. To adjust them, toggle the permissions either on or off, and then press the <strong>Update Permissions</strong> button at the bottom of the modal.
                                                    </Typography>
                                                }
                                                secondary={
                                                    <img className="res-img" src={selectPermissions} alt="change permissions" />
                                                }
                                            />
                                        </ListItem>     
                                    </List>
                                </Grid>  

                            </AccordionDetails>
                        </Accordion>
                        <Accordion 
                            expanded={expanded.a === 2}
                            onChange={handleChange('a', 2)}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>How do I view a user's part number logs??</AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography>To view a user's part number history:</Typography>
                                    </Grid>
                                    <List>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar variant="square">
                                                    <HamburgerIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText 
                                                primary={
                                                    <Typography>
                                                        Access the <strong>Hamburger Navigation Menu</strong> in the upper-left corner of the screen. Within the <strong>Admin Tools</strong> collapsible sub-menu, access the <strong>User Control</strong> link.
                                                    </Typography>
                                                }
                                                secondary={
                                                    <img className="res-img" src={adminDropdown} alt="dropdown" />
                                                }
                                            />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar variant="square">
                                                    <ClipboardIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText 
                                                primary={
                                                    <Typography>
                                                        Find the user, within the list of users, whose part number history you would like to view, then hover over and press the <ClipboardIcon /> button next to that user's name.
                                                    </Typography>
                                                }
                                                secondary={
                                                    <img className="res-img" src={viewLogs} alt="update permissions" />
                                                }
                                            />
                                        </ListItem>   
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar variant="square">
                                                    <FilterIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText 
                                                primary={
                                                    <Typography>
                                                        In the modal that pops over the user list, you will see a scrollable list of all previously generated part numbers associated with the seelected user, beneath a series of <strong>filter inputs</strong>.  Any combination of filters may be used to syphon the list to a more manageable size.  You can search by date range, part number, or part type.
                                                    </Typography>
                                                }
                                                secondary={
                                                    <img className="res-img" src={partHistory} alt="part history" />
                                                }
                                            />
                                        </ListItem>
                                    </List>
                                </Grid>  
                            </AccordionDetails>
                        </Accordion>
                    </Paper>
                }                
                </div>
            </div>
        </div>
    )
}

export default FaqPage;