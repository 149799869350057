import { makeStyles } from '@material-ui/core';

const homeStyles = makeStyles((theme) => ({
    formControl: {        
        margin: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0),
        [theme.breakpoints.up('675')]: {            
            margin: theme.spacing(3),
            marginRight: theme.spacing(0),
        }        
    },
    formControlSm: {        
        margin: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0),
        [theme.breakpoints.up('675')]: {            
            margin: theme.spacing(3),
            marginRight: theme.spacing(0),
        }        
    },
    formControlButton: {        
        margin: theme.spacing(3),
        [theme.breakpoints.down('675')]: {
            margin: theme.spacing(2),
            marginTop: theme.spacing(1),
        }
    },
    fullWidth: {
        width: '100%',
        maxWidth: '675px',
        margin: theme.spacing(1),
        [theme.breakpoints.down('675')]: {
            margin: theme.spacing(0),
            marginBottom: theme.spacing(1),            
        }
    },
    copyAllBtn: {
        color: '#140a9a',
        textTransform: 'capitalize',
        fontSize: '16px',
        marginLeft: theme.spacing(1), 
        '&:hover': {
            textShadow: '1px 0 0'
        }       
    },
    noTopMargin:{
        marginTop: theme.spacing(0),
    },
    noVertMargin: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
    },    
    grid: {
        display: 'grid',        
        gridTemplateColumns: '1.25fr .5fr 1.2fr',
        [theme.breakpoints.down('675')]: {
            gridTemplateColumns: '1fr',            
        }
    },
    numberItem: {        
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        border: '1px solid lightgrey',
        fontWeight: 'bold',
        fontSize: '18px',
        '&:not(:last-child)':{        
            marginBottom: theme.spacing(1),
        },
        [theme.breakpoints.down('675')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },              
    },
    smPadHorizontal: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    icon: {
        padding: theme.spacing(1),
        color: '#140a9a',
    } ,
    overFlowAuto: {
        overflowY: 'auto',
    },
    loadingContainer: {
        height: '25%',
        
    },
    errorContainer: {
        height: '100%',
    }   
}));

export default homeStyles;