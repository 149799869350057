import { makeStyles } from '@material-ui/core';

const permissionStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        maxHeight: '100%',
        '& .user-list-container': {
            width: '100%',
            maxWidth: '725px',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(2),
            '& .scrollable-list': {
                marginTop: theme.spacing(2),
               maxHeight: '100%',
               overflow: 'auto'

            },
            
            '& > .MuiTypography-root': {
                paddingBottom: theme.spacing(2),
            }
        }
    },
    modalRoot: {
        position: 'absolute',
        width: '100%',
        maxWidth: '675px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        '& .modal-body': {
            position: 'relative',
            '& .close-button': {
                position: 'absolute',
                top: 0,
                right: 0,
            },
            '& .MuiGrid-item > .MuiTypography-root': {
                paddingBottom: theme.spacing(2),
            },
            '& .MuiAlert-root': {
                marginTop: theme.spacing(2),
                fontSize: 15
            }
        }
    }
}));

export default permissionStyles;