import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box,  Paper,  Grid, List, ListItem, ListItemText, ListItemSecondaryAction,
    CircularProgress, IconButton, SvgIcon, Modal, Typography, Divider, Button, Tooltip } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CustomSwitch from '../../CustomSwitch/';
import useStyles from './userPermissionStyles';
import fetchFromApi from '../../../apiFetch';
import ViewParts from '../../ViewParts';


const UserPermissions = () => {
    const classes = useStyles();
    const [ users, setUsers ] = useState(undefined);
    const [ modalOpen, setModalOpen ] = useState(false);
    const [ modalBody, setModalBody ] = useState(undefined);

    const getUsers = useCallback(async () => {
        

        await fetchFromApi({ controller: "admin", method: "getAllUsers", refreshToken: true })            
            .then(data => {
                setUsers(data.users);
                
            })
            .catch(error => {
               console.log(error);
            });

    }, []);

    const getUserPermissions = user => () => {
        
        setModalOpen(true);
        
        setModalBody(<UserPermissionModalBody closeFunc={handleClose(true)} user={user} />);
    }
    const getUserPartLogs = user => () => {
        setModalOpen(true);
        
        setModalBody(<PartLogModalBody closeFunc={handleClose(false)} user={user} />);
    }

    const handleClose = (fetchUsers) => () => {
        setModalOpen(false);
        setModalBody(undefined);
        fetchUsers && getUsers();
    }

    useEffect(() => {
        if (!users) {
            getUsers();
        }
    }, [getUsers, users]);
    
    return(
        users
        ?   <Box className={classes.root}>
                <Paper variant="outlined" className="user-list-container">
                    <Typography align="center" variant='h5'>User Control</Typography>
                    <Divider />
                    <Box className="scrollable-list">
                        <List>
                        {
                            users.map((user, ind) => (
                                <ListItem dense key={ind}>
                                    <ListItemText 
                                        
                                        primary={ user.displayName } 
                                        secondary={ user.isActive ? "Active" : "Inactive" } 
                                    />
                                    
                                    <ListItemSecondaryAction>
                                        <Tooltip title="View User Part Numbers">
                                            <IconButton onClick={ getUserPartLogs(user) }>
                                                <SvgIcon>
                                                    <path fill="currentColor" d="M19 3H14.82C14.4 1.84 13.3 1 12 1S9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3M12 3C12.55 3 13 3.45 13 4S12.55 5 12 5 11 4.55 11 4 11.45 3 12 3M7 7H17V5H19V19H5V5H7V7M12 17V15H17V17H12M12 11V9H17V11H12M8 12V9H7V8H9V12H8M9.25 14C9.66 14 10 14.34 10 14.75C10 14.95 9.92 15.14 9.79 15.27L8.12 17H10V18H7V17.08L9 15H7V14H9.25" />
                                                </SvgIcon>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Update User Permissions">
                                            <IconButton onClick={ getUserPermissions(user) }>
                                                <SvgIcon>
                                                    <path fill="currentColor" d="M11 9C11 10.66 9.66 12 8 12C6.34 12 5 10.66 5 9C5 7.34 6.34 6 8 6C9.66 6 11 7.34 11 9M14 20H2V18C2 15.79 4.69 14 8 14C11.31 14 14 15.79 14 18M22 12V14H13V12M22 8V10H13V8M22 4V6H13V4Z" />
                                                </SvgIcon>
                                            </IconButton>
                                        </Tooltip>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))
                        }
                        </List>
                    </Box>
                </Paper>
                <Modal
                    disableBackdropClick
                    open={ modalOpen }
                    onClose={ handleClose }
                >
                    <div className={ classes.modalRoot }>
                        { modalBody }
                    </div>
                </Modal>
            </Box>
        :   <CircularProgress color="primary" />
    )
}

export default UserPermissions;

const UserPermissionModalBody = ({ user, closeFunc }) => {
    const mounted = useRef();
    const [ userPermissionState, setUserPermissionState ] = useState(undefined);
    const [ loading, setLoading ] = useState(false);
    const [ updating, setUpdating ] = useState(false);
    const [ alert, setAlert ] = useState(undefined);
    

    const getUserPerms = useCallback(async () => {
        
        setLoading(true);
        await fetchFromApi({ 
                controller: "admin", 
                method: "getRegisteredUserPermissions",  
                params: [ user.id ], 
                refreshToken: true 
            })            
            .then(data => {
                if(mounted.current){
                    setUserPermissionState(data.userPermissions);                    
                    setLoading(false);
                }
            })
            .catch(error => {
               console.log(error);
            });

    },[user, mounted]);
    
    useEffect(() => {
        mounted.current = true;
        if(!userPermissionState && mounted.current){
            getUserPerms();
        }

        return () => mounted.current = false;

    }, [userPermissionState, getUserPerms, mounted]);

    const handleSwitch = key => () => {
        
        setUserPermissionState({...userPermissionState, [key]: !userPermissionState[key]});
    }

    const createAlert = ({type, message}) => {
        return (
            <Alert 
                variant="filled"
                severity={ type }
                action={
                    <IconButton
                        size="small"
                        color="inherit"
                        onClick={ () => setAlert(undefined) }
                    >
                        <SvgIcon fontSize="inherit">
                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                        </SvgIcon>
                    </IconButton>
                }
            >
                {message}
            </Alert>
        );
    }

    const handleUpdate = async () => {
        const hasPermissions = Object.keys(userPermissionState)
            .some( key => key !== 'isActive' && userPermissionState[key]);        
        
        if(userPermissionState.isActive && !hasPermissions) {            
            setAlert(createAlert({type: "warning", message: "Please select at least one permission for active user"}));
            return;
        }  

        setUpdating(true); 
        
        fetchFromApi({
            controller: "admin",
            method: "updateUserPermissions",
            params: [user.id],
            fetchOptions: {
                method: 'POST',
                body: JSON.stringify(userPermissionState)
            },
            refreshToken: true,
        })
        .then(data => {
            if(mounted.current) {
                setUpdating(false);
                setAlert(createAlert({ type: "success", message: data.message }));   
            }         
        })
        .catch(error => {
            if(mounted.current) {                
                setAlert(createAlert({type: "error", message: error.message}));
                setLoading(false);
                console.log(error);
            }
        });
    }
    
    return(
        
        <Grid container direction="column" className="modal-body">
            <Grid item>
                <Typography variant="h5">{ user.displayName }</Typography>
                <IconButton
                    className="close-button"
                    size="small"
                    color="inherit"
                    onClick={ closeFunc }
                >
                    <SvgIcon fontSize="inherit">
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </SvgIcon>
                </IconButton>
                <Divider />
            </Grid>
            {
                !loading
                ?
                    <Grid item container direction="column">                     
                        <Grid item>                        
                            <List>
                            {    
                                userPermissionState && Object.keys(userPermissionState).map(key => {
                                    let title = key.split(/(?=[A-Z])/).slice(1).join(' ');

                                    return(
                                        <ListItem key={ key }>
                                            <ListItemText primary={ title } />
                                            <ListItemSecondaryAction>
                                                <CustomSwitch 
                                                    disabled={ key !== 'isActive' && !userPermissionState.isActive }
                                                    color="primary" 
                                                    checked={ userPermissionState[key] } 
                                                    onChange={ handleSwitch(key) }
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )
                                })
                            }
                            </List>                        
                        </Grid>
                        <Grid item container justify="flex-end">
                            
                            <Grid item>
                                <Button 
                                    disabled={ updating }
                                    variant="outlined" 
                                    color="primary"
                                    onClick={ handleUpdate }
                                >
                                   Update Permissions
                                   { updating && " " }
                                   { updating && <CircularProgress /> }
                                </Button>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                :   <CircularProgress />
            }
            {
                alert
            }
        </Grid>
    )
}

const PartLogModalBody = ({ user, closeFunc }) => {
    
    
    return (
        <Grid container direction="column" className="modal-body">
            <Grid item>
                <Typography variant="h5">{ user.displayName }</Typography>
                <IconButton
                    className="close-button"
                    size="small"
                    color="inherit"
                    onClick={ closeFunc }
                >
                    <SvgIcon fontSize="inherit">
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                    </SvgIcon>
                </IconButton>
                
            </Grid>
            <ViewParts userId={user.id} />
        </Grid>
    )
}