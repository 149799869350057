import { makeStyles } from '@material-ui/core';

const switchStyles = makeStyles(({
    root: {
        '& .Mui-checked': {
            color: '#140a9a',
            '&.Mui-disabled': {
                color: '#bdbdbd',
                '& + .MuiSwitch-track': {
                    opacity: 0.12,
                },
            },
            '&:hover': {
                backgroundColor: 'rgba(5, 7, 44, 0.04)',
            },
            '& + .MuiSwitch-track': {
                backgroundColor: '#140a9a',
                opacity: 0.6,
            },
            '& .MuiTouchRipple-child': {
                backgroundColor: '#140a9a',
            }
        },
        
    },
    
}));

export default switchStyles;