import { makeStyles } from '@material-ui/core';


const navStyles = makeStyles(theme => ({
        navColor: {
            backgroundColor: theme.navColor,
        },
        navRoot: {
            '& .MuiBadge-badge': {
                height: '12px',
                minWidth: '12px',
            },
            '& .MuiListItemSecondaryAction-root .MuiBadge-badge': {
                height: '20px',
                minWidth: '20px',
                marginRight: '15px',
                
            }
        }
    }
))

export default navStyles;