import React, { useState } from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SvgIcon from '@material-ui/core/SvgIcon';
import Backdrop from '@material-ui/core/Backdrop';
import Popover from '@material-ui/core/Popover';
import { CloseIcon } from '../../icons';
import { Button, Divider, Grid, IconButton, TextField, Typography } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        [theme.breakpoints.down(450)]: {
            bottom: theme.spacing(3),
            right: 3,
        }
    },
    paper: {
        padding: theme.spacing(1),
        width: 550,
        maxWidth: 'calc(100% - 112px)',
        minHeight: 250,
        zIndex: 1500
    },
    popoverBody: {
        width: '100%',
        position: 'relative',
        '& .MuiIconButton-root': {
            position: 'absolute',
            top: 0,
            right: 0,
        }
    },
    formBody: {

    },
    fabClass: {
        position: 'relative',
        color: '#140a9a',
        '&.active': {
            border: '2px solid #140a9a',
            
            '&::before': {
                display: 'inline-block',
                content: "''",
                width: 0,
                height: 0,
                position: 'absolute',
                left: -16,
                right: '100%',
                borderTop: '10px solid transparent',
                borderBottom: '10px solid transparent',
                borderRight: '10px solid #140a9a'
            }
        }
    },
    mainButton: {
        backgroundColor: '#140a9a',

    },
    bugForm: {
        '& .MuiTypography-h6': {
            width: '100%',
            paddingBottom: theme.spacing(1),
            paddingLeft: theme.spacing(1),
        },
        '& .form-body': {
            
            padding: theme.spacing(1),
        }
    }

}));

const FeatureRequest = () => {
    const classes = useStyles();
    const [ open, toggleOpen ] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ activeBtn, setActiveBtn ] = useState(null);
    const [ form, setForm ] = useState(null);

    const handleToggle = () => {
        
        toggleOpen(!open);
    }    

    const handlePopoverOpen = formComponent => e => {        
        
        e.currentTarget.classList.toggle("active");
        setForm(formComponent)
        setActiveBtn(e.currentTarget);
        setAnchorEl(e.currentTarget.parentElement.parentElement);
    }
    
    const handlePopoverClose = () => {

        activeBtn.classList.toggle("active");
        setForm(null);
        setActiveBtn(null);
        setAnchorEl(null);
    }
    
    return (
        <div style={{ position: 'relative' }}>
            <Backdrop open={open} style={{ zIndex: 1000 }} />
            <Backdrop open={Boolean(anchorEl)} style={{ zIndex: 1250, backgroundColor: 'rgba(0,0,0,0.2)' }} />            
            <SpeedDial
                direction="up"
                ariaLabel="feature-and-bug-request"
                className={classes.root}                
                FabProps={{
                    size: "medium",
                    className: classes.mainButton,
                    onClick: handleToggle
                }}
                icon={                    
                    <SpeedDialIcon 
                        icon={
                            <SvgIcon>
                                <path d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z"></path>
                            </SvgIcon>
                        } 
                        openIcon={
                            <SvgIcon>
                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                            </SvgIcon>
                        }
                    />                                        
                }
                open={open} 
            >
                <SpeedDialAction 
                    classes={{fab: classes.fabClass}}
                    icon={
                        <SvgIcon>
                            <path d="M23 12l-2.44-2.78.34-3.68-3.61-.82-1.89-3.18L12 3 8.6 1.54 6.71 4.72l-3.61.81.34 3.68L1 12l2.44 2.78-.34 3.69 3.61.82 1.89 3.18L12 21l3.4 1.46 1.89-3.18 3.61-.82-.34-3.68L23 12zm-10 5h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                        </SvgIcon>
                    } 
                    tooltipTitle="Request a Feature"
                    FabProps={{
                        size: "medium",
                        onClick: handlePopoverOpen(<FeatureForm />)                      
                    }} 
                                  
                />
                <SpeedDialAction 
                    classes={{fab: classes.fabClass}}
                    icon={
                        <SvgIcon>
                            <path d="M20 8h-2.81c-.45-.78-1.07-1.45-1.82-1.96L17 4.41 15.59 3l-2.17 2.17C12.96 5.06 12.49 5 12 5c-.49 0-.96.06-1.41.17L8.41 3 7 4.41l1.62 1.63C7.88 6.55 7.26 7.22 6.81 8H4v2h2.09c-.05.33-.09.66-.09 1v1H4v2h2v1c0 .34.04.67.09 1H4v2h2.81c1.04 1.79 2.97 3 5.19 3s4.15-1.21 5.19-3H20v-2h-2.09c.05-.33.09-.66.09-1v-1h2v-2h-2v-1c0-.34-.04-.67-.09-1H20V8zm-6 8h-4v-2h4v2zm0-4h-4v-2h4v2z"></path>
                        </SvgIcon>
                    } 
                    tooltipTitle="Report a Bug"
                    FabProps={{
                        
                        size: "medium",  
                        onClick: handlePopoverOpen(<BugForm />)                      
                    }}                       
                />                
            </SpeedDial>
            
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                elevation={4}
                classes={{
                    paper: classes.paper
                }}                
                anchorOrigin={{
                    vertical:  'bottom',
                    horizontal: -12,
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <PopoverBody classes={classes} closeFunc={handlePopoverClose}>
                    {form}
                </PopoverBody>
            </Popover>        
                

        </div>
    )
}

export default FeatureRequest;

const PopoverBody = ({ classes, children, closeFunc=()=>{} }) => {
    
    return (
        <div className={classes.popoverBody}>            
            <IconButton size="small" onClick={closeFunc}>
                <CloseIcon />
            </IconButton>
            {children}
        </div>
    )
}

const BugForm = () => {
    const classes = useStyles();
    const [ formValues, setFormValues ] = useState({
        description: "",
        reproSteps: "",
    });

    const handleChange = field => e => {
        setFormValues({...formValues, [field]: e.target.value });
    }

    return(
        <Grid className={classes.bugForm} direction="column" container>
            <Grid item>
                <Typography variant="h6">
                    Report a Bug
                </Typography>
                <Divider />
            </Grid>
            <Grid item container direction="column" className="form-body">                
                <TextField 
                    variant="outlined" 
                    label="Brief Description (of bug)" 
                    onChange={handleChange("description")} 
                    value={formValues.description} 
                    margin="dense" 
                />
                <TextField
                    multiline
                    variant="outlined"
                    label="Steps to Reproduce"
                    rows={5}
                    rowsMax={5}
                    onChange={handleChange("reproSteps")}
                    value={formValues.reproSteps}
                    margin="dense"
                />
            </Grid>
        </Grid>
    )
}

const FeatureForm = () => {
    return(
        <div>BAR</div>
    )
}
//token zvewtmzl7hpi7k7j2gnjd4s2rxirpyqrymvbe3rwwvnyaccfsqya
//api encoded base64 string "ZXJpay53aGl0ZUBtaW5pbmcua29tYXRzdTp6dmV3dG16bDdocGk3azdqMmduamQ0czJyeGlycHlxcnltdmJlM3J3d3ZueWFjY2ZzcXlh"